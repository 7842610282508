<template>
  <v-container fluid id="privacyPolicy">
    <v-container>
      <v-card class="py-2 py-md-7 px-2 px-md-6">
        <v-row justify="center" align="center">
          <v-col cols="12" sm="12" md="6">
            <h1 class="text-center increasedFontSize ml-md-9 ml-sm-0">
              Privacy Policy
            </h1>
          </v-col>
          <v-col cols="12" sm="12" md="6">
            <v-img
              height="225"
              contain
              src="@/assets/misc/undraw_privacy_protection_nlwy.svg"
            ></v-img>
          </v-col>
        </v-row>
        <v-row justify="center" align="center" class="mt-4">
          <v-col cols="12" sm="12" md="9">
            <p>Privacy Policy will be added soon.</p>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
  </v-container>
</template>

<script>
export default {
  name: "PrivacyPolicy",
  metaInfo: {
    title: "Privacy Policy",
    meta: [
      {
        vmid: "description",
        name: "description",
        content:
          "Admin Privacy Policy Page description lorem ipsum dolor sit amet.",
      },
    ],
  },
};
</script>

<style>
.increasedFontSize {
  font-size: 3.98rem !important;
}
</style>